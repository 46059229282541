import { Box, Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import Image from 'next/image'
import React from 'react'

import { COLOR_THEME, GRAPHIC_PATTERN } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import HeroHome from 'src/components/hero/HeroHome'
import Breadcrumbs from 'src/components/breadcrumbs/Breadcrumbs'
import { getBackgroundType } from 'src/components/breadcrumbs/utils'

const LevelType = {
  HOMEPAGE: 'homepage',
  LEVEL1: 'page_level_1',
  LEVEL2: 'page_level_2',
  LEVEL3: 'page_level_3',
}

const useStyles = makeStyles()((defaultTheme, props) => {
  const {
    page_level,
    background_color,
    backgroundImage,
    imageUrl,
    breadcrumbs,
  } = props

  let height
  let backgroundColor
  let styleBackgroundImage
  let colorHeading
  let colorSubHeading

  if (page_level === LevelType.LEVEL3) {
    height = rem(277)
  } else if (page_level === LevelType.LEVEL2) {
    height = rem(377)
  } else {
    height = rem(576)
  }

  if (!imageUrl) {
    if (background_color === COLOR_THEME.CRISSY_FIELD) {
      backgroundColor = SECONDARY_COLOR.LIGHT['40']
      colorHeading = theme.palette.primary.dark
      colorSubHeading = theme.palette.presidio.color.DARK_GRAY
    } else {
      backgroundColor = theme.palette.primary.dark
      colorHeading = theme.palette.presidio.color.NEAR_WHITE
      colorSubHeading = theme.palette.presidio.color.NEAR_WHITE
    }

    styleBackgroundImage = {
      width: '100%',
      position: 'absolute',
      height: '100%',
      right: 0,
      bottom: 0,
      background: `url(${backgroundImage})`,
      backgroundCover: 'center',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      zIndex: -1,
      backgroundRepeat: 'no-repeat',
      opacity: background_color === COLOR_THEME.CYPRESS_GREEN ? 0.2 : 1,
      [theme.breakpoints.up('xl')]: {
        backgroundSize: 'contain',
        backgroundPosition: 'bottom right',
        opacity: 1,
      },
    }
  } else {
    styleBackgroundImage = {
      background: `url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundCover: 'center',
      backgroundPosition: 'center',
      width: '100%',
      position: 'absolute',
      height: '100%',
      zIndex: -1,
    }
  }

  return {
    wrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: breadcrumbs ? 'space-between' : 'flex-end',
      alignItems: 'flex-start',
      minHeight: height,
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        minHeight: height,
      },
      zIndex: 0,
      backgroundColor: backgroundColor,
    },
    backgroundImageLayer: styleBackgroundImage,
    breadcrumbs: {
      width: '100%',
      padding: `${rem(40)} ${rem(24)} 0 ${rem(24)}`,
      zIndex: 1,
      [theme.breakpoints.up('md')]: {
        padding: `${rem(40)} ${rem(40)} 0 ${rem(40)}`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(28)} ${rem(156)} 0 ${rem(156)}`,
      },
    },
    heroTypeBlock: {
      padding: `${rem(40)} ${rem(24)}`,
      [theme.breakpoints.up('md')]: {
        padding: rem(40),
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(40)} ${rem(156)}`,
      },
      color:
        backgroundColor === COLOR_THEME.CRISSY_FIELD
          ? theme.palette.secondary.dark
          : theme.palette.presidio.color.NEAR_WHITE,
      background: imageUrl
        ? 'linear-gradient(180deg, rgba(25, 24, 22, 0) 0%, rgba(25, 24, 22, 0.75) 54.25%)'
        : 'none',
      width: '100%',
    },
    heading: {
      marginBottom: rem(16),
      [theme.breakpoints.down('lg')]: {
        fontSize: rem(56),
      },
      color: colorHeading,
    },
    subHeading: {
      ...theme.typography.body.default,
      fontSize: rem(18),
      color: colorSubHeading,
    },
  }
})

export default function Hero(props) {
  const { data: allData } = props
  if (!allData) return null

  const {
    data,
    enable_breadcrumb: enableBreadcrumb,
    breadcrumb: breadcrumbs,
  } = allData
  if (!data) return null

  const { hero, section_id } = data
  if (!hero) return null

  const {
    page_level,
    heading,
    sub_heading,
    background_color,
    graphic_pattern,
    image,
  } = hero
  if (!heading && !sub_heading) return null

  if (page_level === LevelType.HOMEPAGE) return <HeroHome data={data} />

  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))

  const backgroundImage = React.useMemo(() => {
    if (image?.url) {
      return image?.url
    }

    if (background_color === COLOR_THEME.CRISSY_FIELD) {
      if (graphic_pattern === GRAPHIC_PATTERN.BEACH_STRAWBERRY) {
        if (xlUp)
          return '/assets/patterns/beach-strawberry/CrissyField_FullWidth.png'
        return '/assets/patterns/beach-strawberry/CrissyField_UnCropped.png'
      }
      if (graphic_pattern === GRAPHIC_PATTERN.MANZANITA) {
        if (xlUp) return '/assets/patterns/manzanita/CrissyField_FullWidth.png'
        return '/assets/patterns/manzanita/CrissyField_UnCropped.png'
      }
      if (graphic_pattern === GRAPHIC_PATTERN.PELICAN) {
        if (xlUp)
          return '/assets/patterns/pelican-footprints/CrissyField_FullWidth.png'
        return '/assets/patterns/pelican-footprints/CrissyField_UnCropped.png'
      }
    } else {
      if (graphic_pattern === GRAPHIC_PATTERN.BEACH_STRAWBERRY) {
        if (xlUp)
          return '/assets/patterns/beach-strawberry/CypressGreen_FullWidth.png'
        return '/assets/patterns/beach-strawberry/CypressGreen_UnCropped.png'
      }
      if (graphic_pattern === GRAPHIC_PATTERN.MANZANITA) {
        if (xlUp) return '/assets/patterns/manzanita/CypressGreen_FullWidth.png'
        return '/assets/patterns/manzanita/CypressGreen_UnCropped.png'
      }
      if (graphic_pattern === GRAPHIC_PATTERN.PELICAN) {
        if (xlUp)
          return '/assets/patterns/pelican-footprints/CypressGreen_FullWidth.png'
        return '/assets/patterns/pelican-footprints/CypressGreen_UnCropped.png'
      }
    }

    return null
  }, [image, graphic_pattern, background_color, xlUp])

  const { classes } = useStyles({
    page_level,
    background_color,
    backgroundImage,
    imageUrl: image?.url,
    breadcrumbs,
  })

  const backgroundType = image?.url
    ? 'image'
    : getBackgroundType(background_color)

  return (
    <div
      className={`${classes.wrapper} module`}
      id={section_id}
      data-id="section"
    >
      {backgroundType !== 'image' ? (
        <div className={classes.backgroundImageLayer} />
      ) : (
        backgroundImage && (
          <Image
            className={classes.backgroundImageLayer}
            src={backgroundImage}
            alt={image?.alt || 'background pattern'}
            title={image?.title}
            layout="fill"
            objectFit="cover"
            priority
          />
        )
      )}

      {enableBreadcrumb && breadcrumbs && (
        <Box className={classes.breadcrumbs}>
          <Breadcrumbs
            data={{
              breadcrumbs,
              backgroundType,
              customBreadCrumbs: allData.customBreadCrumbs || {},
            }}
            backgroundType={backgroundType}
          />
        </Box>
      )}

      <div className={classes.heroTypeBlock}>
        <Typography variant="h1" className={classes.heading}>
          {heading}
        </Typography>
        <Typography className={classes.subHeading}>{sub_heading}</Typography>
      </div>
    </div>
  )
}

Hero.propTypes = {
  data: PropTypes.shape({
    hero: PropTypes.shape({
      page_level: PropTypes.oneOf([
        LevelType.HOMEPAGE,
        LevelType.LEVEL1,
        LevelType.LEVEL2,
        LevelType.LEVEL3,
      ]),
      heading: PropTypes.string,
      sub_heading: PropTypes.string,
      graphic_pattern: PropTypes.oneOf([
        GRAPHIC_PATTERN.BEACH_STRAWBERRY,
        GRAPHIC_PATTERN.MANZANITA,
        GRAPHIC_PATTERN.PELICAN,
      ]),
      background_color: PropTypes.oneOf([
        COLOR_THEME.CRISSY_FIELD,
        COLOR_THEME.CYPRESS_GREEN,
        COLOR_THEME.THE_BAKER_BEACH,
      ]),
      image: PropTypes.oneOfType([
        PropTypes.shape({
          url: PropTypes.string,
        }),
      ]),
    }),
  }),
}
