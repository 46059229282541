import React from 'react'
import MissionStatement from 'src/components/mission-statement/MissionStatement'

const NotFound = () => {
  const notFoundData = {
    section_title: 'Not Found',
    section_id: 'not-found-404',
    mission_statement: {
      background_color: 'crissy_field_green',
      background_pattern: true,
      desktop_image: {},
      mobile_image: {},
      errorTitle: '404 Error',
      body: 'It looks like this URL is not available. Let’s get you back on track!',
      cta: {
        type: 'url',
        value: '#',
        url: '/',
        name: '#',
        title: 'RETURN TO HOMEPAGE',
        target: '',
      },
    },
  }
  return <MissionStatement data={notFoundData} />
}

export default NotFound
