import React from 'react'
import generateRandomString from 'src/common/utils/js/generateRandomString'

function CalenderEvent(props) {
  const { color, height, width } = props
  const idString = generateRandomString()
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_841_251${idString})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1V1.5H6V1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V1.5H3C1.34315 1.5 0 2.84315 0 4.5V7.5V12.5C0 14.1569 1.34315 15.5 3 15.5H13C14.6569 15.5 16 14.1569 16 12.5V7.5V4.5C16 2.84315 14.6569 1.5 13 1.5H12V1ZM14 6.5V4.5C14 3.94772 13.5523 3.5 13 3.5H12V4C12 4.55228 11.5523 5 11 5C10.4477 5 10 4.55228 10 4V3.5H6V4C6 4.55228 5.55228 5 5 5C4.44772 5 4 4.55228 4 4V3.5H3C2.44772 3.5 2 3.94772 2 4.5V6.5H14ZM2 8.5H14V12.5C14 13.0523 13.5523 13.5 13 13.5H3C2.44772 13.5 2 13.0523 2 12.5V8.5Z"
          fill={color || '#191816'}
        />
        <path
          d="M24 11.5C24 10.3954 23.1046 9.5 22 9.5V20.5C22 21.0523 21.5523 21.5 21 21.5H8C8 22.6046 8.89543 23.5 10 23.5H21C22.6569 23.5 24 22.1569 24 20.5V11.5Z"
          fill={color || '#191816'}
        />
        <path
          d="M20 7.5C20 6.39543 19.1046 5.5 18 5.5V16.5C18 17.0523 17.5523 17.5 17 17.5H4C4 18.6046 4.89543 19.5 6 19.5H17C18.6569 19.5 20 18.1569 20 16.5V7.5Z"
          fill={color || '#191816'}
        />
      </g>
      <defs>
        <clipPath id={`clip0_841_251${idString}`}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CalenderEvent
