import dynamic from 'next/dynamic'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import AlertBanner from 'src/components/alert-banner/AlertBanner'
import Hero from 'src/components/hero/Hero'
import SegmentedControl from 'src/components/segmented-control/SegmentedControl'
import AttractionMetricsBar from 'src/components/attraction-metrics-bar/AttractionMetricsBar'
import generateRandomString from 'src/common/utils/js/generateRandomString'
import Loader from 'src/common/components/loader/Loader'

export const MODULES = {
  QUICK_LINKS: 'quick_links',
  SHORT_DESCRIPTION: 'short_description',
  FAQ: 'faq',
  HERO: 'hero',
  LONG_DESCRIPTION: 'long_description',
  ALERT_BANNER: 'alert_banner',
  QUOTE: 'quote',
  MISSION_STATEMENT: 'mission_statement',
  SHUTTLE_MAP: 'shuttle_routes_maps',
  TEXT_MEDIA: 'text_media',
  TAGS: 'tags',
  CARD_WITH_CTA: 'card_with_cta',
  CARD_CAROUSEL: 'card_carousel',
  SEGMENTED_CONTROL: 'segmented_control',
  FEATURED_CARDS: 'featured_cards',
  CONTACT: 'contact',
  MEDIA_CAROUSEL: 'media_carousel',
  MULTICOLUMN_CONTENT: 'multi_column_content',
  ATTRACTION_METRICS_BAR: 'attraction_metrics_bar',
  CARD_SET: 'card_set',
  REAL_ESTATE_LISTINGS: 'real_estate_listings',
  REGION_MAP: 'region_map',
  SHUTTLE_ROUTES_SCHEDULE: 'shuttle_routes_schedule',
  INTERACTIVE_MAP: 'interactive_map',
  JOB_LISTINGS: 'job_listings',
  PARK_OVERVIEW: 'park_overview',
}

Object.freeze(MODULES)

const DynamicLongDescription = dynamic(
  () => import('src/components/long-description/LongDescription'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicShortDescription = dynamic(
  () => import('src/components/short-description/ShortDescription'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicQuickLinks = dynamic(
  () => import('src/components/quick-links/QuickLinks'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicFaq = dynamic(() => import('src/components/faq/Faq'), {
  loading: () => <Loader iconWidth={75} iconHeight={100} />,
})
const DynamicShuttleMap = dynamic(
  () => import('src/components/shuttle-map/ShuttleMap'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicMediaCarousel = dynamic(
  () => import('src/components/media-carousel/Standard'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicTextAndMedia = dynamic(
  () => import('src/components/text-and-media/TextAndMedia'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicQuote = dynamic(() => import('src/components/quote/Quote'), {
  loading: () => <Loader iconWidth={75} iconHeight={100} />,
})
const DynamicTags = dynamic(() => import('src/components/tags/Tags'), {
  loading: () => <Loader iconWidth={75} iconHeight={100} />,
})
const DynamicCardsWithCta = dynamic(
  () => import('src/components/cards-with-cta/CardsWithCta'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicMissionStatement = dynamic(
  () => import('src/components/mission-statement/MissionStatement'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicCardCarousel = dynamic(
  () => import('src/components/card-carousel/CardCarousel'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicFeaturedCards = dynamic(
  () => import('src/components/featured-cards/FeaturedCards'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicMutlicolumnContent = dynamic(
  () => import('src/components/multicolumn-content/MutlicolumnContent'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)
const DynamicCardSet = dynamic(
  () => import('src/components/card-set/CardSet'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
    ssr: false,
  }
)
const DynamicPropertyCardSet = dynamic(
  () => import('src/components/property-card-set/PropertyCardSet'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
    ssr: false,
  }
)

const DynamicRegionMap = dynamic(
  () => import('src/components/region-map/RegionMap'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
    ssr: false,
  }
)

const DynamicShuttleSchedule = dynamic(
  () => import('src/components/shuttle-schedule/ShuttleSchedule'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)

const DynamicInteractiveMap = dynamic(
  () => import('src/components/interactive-map/InteractiveMap'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)

const DynamicJobListings = dynamic(
  () => import('src/components/job-listings/JobListings'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)

const DynamicParkOverview = dynamic(
  () => import('src/components/park-overview/ParkOverview'),
  {
    loading: () => <Loader iconWidth={75} iconHeight={100} />,
  }
)

export default function getComponent(acf_fc_layout) {
  switch (acf_fc_layout) {
    case MODULES.QUICK_LINKS:
      return ({ data }) => (
        <DynamicQuickLinks key={data.section_id} data={data} />
      )
    case MODULES.SHORT_DESCRIPTION:
      return ({ data }) => (
        <DynamicShortDescription key={data.section_id} data={data} />
      )
    case MODULES.FAQ:
      return ({ data }) => <DynamicFaq key={data.section_id} data={data} />
    case MODULES.HERO:
      return ({ data, enable_breadcrumb, breadcrumb, customBreadCrumbs }) => (
        <Hero
          key={data.section_id}
          data={{ data, enable_breadcrumb, breadcrumb, customBreadCrumbs }}
        />
      )
    case MODULES.LONG_DESCRIPTION:
      return ({ data }) => (
        <DynamicLongDescription key={data.section_id} data={data} />
      )
    case MODULES.ALERT_BANNER:
      return ({ data }) => (
        <AlertBanner
          key={data.section_id || generateRandomString()}
          data={data}
        />
      )
    case MODULES.QUOTE:
      return ({ data }) => <DynamicQuote key={data.section_id} data={data} />
    case MODULES.MISSION_STATEMENT:
      return ({ data }) => (
        <DynamicMissionStatement key={data.section_id} data={data} />
      )
    case MODULES.SHUTTLE_MAP:
      return ({ data }) => (
        <DynamicShuttleMap key={data.section_id} data={data} />
      )
    case MODULES.TEXT_MEDIA:
      return ({ data, order }) => (
        <DynamicTextAndMedia key={data.section_id} data={data} order={order} />
      )
    case MODULES.TAGS:
      return ({ data }) => <DynamicTags key={data.section_id} data={data} />
    case MODULES.CARD_WITH_CTA:
      return ({ data }) => (
        <DynamicCardsWithCta key={data.section_id} data={data} />
      )
    case MODULES.CARD_CAROUSEL:
      return ({ data }) => (
        <DynamicCardCarousel key={data.section_id} data={data} />
      )
    case MODULES.SEGMENTED_CONTROL:
      return ({ data }) => (
        <SegmentedControl
          data={data}
          key={data.section_id || generateRandomString()}
        />
      )
    case MODULES.FEATURED_CARDS:
      return ({ data }) => (
        <DynamicFeaturedCards key={data.section_id} data={data} />
      )
    case MODULES.CONTACT:
      return ({ data }) => (
        <DynamicParkOverview
          key={data.section_id}
          data={{
            park_overview: {
              ...data?.contact,
              background_color: data.contact.background_color,
            },
          }}
        />
      )
    case MODULES.MEDIA_CAROUSEL:
      return ({ data }) => (
        <DynamicMediaCarousel key={data.section_id} data={data} />
      )
    case MODULES.MULTICOLUMN_CONTENT:
      return ({ data }) => (
        <DynamicMutlicolumnContent key={data.section_id} data={data} />
      )
    case MODULES.ATTRACTION_METRICS_BAR:
      return ({ data }) => (
        <AttractionMetricsBar key={data.section_id} data={data} />
      )
    case MODULES.CARD_SET:
      return ({ data }) => <DynamicCardSet key={data.section_id} data={data} />
    case MODULES.REAL_ESTATE_LISTINGS:
      return ({ data }) => (
        <DynamicPropertyCardSet key={data.section_id} data={data} />
      )
    case MODULES.REGION_MAP:
      return ({ data }) => (
        <LazyLoadComponent>
          <DynamicRegionMap key={data.section_id} data={data} />
        </LazyLoadComponent>
      )
    case MODULES.SHUTTLE_ROUTES_SCHEDULE:
      return ({ data }) => (
        <DynamicShuttleSchedule key={data.section_id} data={data} />
      )
    case MODULES.INTERACTIVE_MAP:
      return ({ data }) => (
        <DynamicInteractiveMap key={data.section_id} data={data} />
      )
    case MODULES.JOB_LISTINGS:
      return ({ data }) => (
        <DynamicJobListings key={data.section_id} data={data} />
      )
    case MODULES.PARK_OVERVIEW:
      return ({ data }) => (
        <DynamicParkOverview key={data.section_id} data={data} />
      )
    default:
      break
  }
  return null
}
