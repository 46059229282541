/* eslint-disable guard-for-in */
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Typography, useMediaQuery } from '@mui/material'
import theme, { SECONDARY_COLOR, SHADOW } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'

const BACKGROUND_COLORS = {
  CYPRESS_GREEN: theme.palette.primary.dark,
  BAKER_BEACH: theme.palette.presidio.color.LIGHT_BACKGROUND,
}

const useStyles = makeStyles()((_theme, { backgroundColor }, classes) => {
  const darkBg = backgroundColor === 'cypress_green'
  return {
    wrapper: {
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'flex-end',
        padding: `${rem(16)} ${rem(40)} ${rem(8)}`,
        backgroundColor: darkBg
          ? BACKGROUND_COLORS.CYPRESS_GREEN
          : BACKGROUND_COLORS.BAKER_BEACH,
        boxShadow: SHADOW.LEVEL_THREE,
        position: 'sticky',
        top: rem(72), // height of nav bar
        zIndex: '50',
        gap: rem(24),
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(16)} ${rem(156)} ${rem(8)}`,
      },
    },
    tabContainer: {
      [theme.breakpoints.up('lg')]: {
        cursor: 'pointer',
        padding: `${rem(12)} ${rem(0)}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: rem(4),
        [`&:hover .${classes.bar}`]: {
          background: `${SECONDARY_COLOR.DARK[80]} !important`,
        },
        [`&:active .${classes.bar}`]: {
          background: `${theme.palette.primary.light} !important`,
        },
      },
    },
    tab: {
      [theme.breakpoints.up('lg')]: {
        ...theme.typography.label,
        fontWeight: 500,
        color: darkBg
          ? BACKGROUND_COLORS.BAKER_BEACH
          : BACKGROUND_COLORS.CYPRESS_GREEN,
      },
    },
    bar: {
      [theme.breakpoints.up('lg')]: {
        height: rem(3),
        width: '100%',
        background: 'transparent',
        borderRadius: rem(1),
      },
    },
  }
})

function SegmentedControl(props) {
  const { data = {} } = props

  const { segmented_control = {} } = data

  const { anchor_link: anchorLinks = [], background_color: backgroundColor } =
    segmented_control || []

  const { classes } = useStyles({ backgroundColor })

  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))

  const intersectionObserver = useRef(null)
  const sectionsRef = useRef([])

  const [activeSection, setActiveSection] = useState(null)

  useEffect(() => {
    if (!lgUp) {
      return () => {
        if (intersectionObserver.current) {
          intersectionObserver.current.disconnect()
        }
      }
    }

    const sections = document.querySelectorAll('[data-id="section"]')
    sectionsRef.current = Array.from(sections)

    intersectionObserver.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.id
          if (entry.isIntersecting) {
            setActiveSection(sectionId)
          }
        })
      },
      { threshold: 0.5 }
    )

    sectionsRef.current.forEach((sectionRef) => {
      intersectionObserver.current.observe(sectionRef)
    })

    return () => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect()
      }
    }
  }, [lgUp])

  useEffect(() => {
    if (!lgUp) {
      return
    }
    sectionsRef.current = anchorLinks.reduce((acc, { value }) => {
      acc[value] = React.createRef()
      return acc
    }, {})
  }, [lgUp, anchorLinks])

  if (!anchorLinks || anchorLinks.length === 0) return null

  // Segmented control needs to be hidden below large
  if (!lgUp) return null

  const handleLinkClick = (value) => {
    const newSection = document.getElementById(value)
    if (newSection) {
      newSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    anchorLinks &&
    anchorLinks.length !== 0 && (
      <div
        className={classes.wrapper}
        id="segmented-control"
        data-weglot="translate"
      >
        {anchorLinks.map(({ label, value }) => (
          <Box
            className={classes.tabContainer}
            key={value}
            ref={sectionsRef.current[value]}
            tabIndex={0}
            onClick={() => handleLinkClick(value)}
            onKeyDown={() => handleLinkClick(value)}
            data-ga-location="segmented_control"
          >
            <Typography className={classes.tab}>{label}</Typography>
            <Box
              className={classes.bar}
              style={{
                background:
                  activeSection === value
                    ? theme.palette.primary.light
                    : 'transparent',
              }}
              data-category="bar"
              data-id={value}
            />
          </Box>
        ))}
      </div>
    )
  )
}

export default SegmentedControl
