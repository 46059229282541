import { Box, Typography } from '@mui/material'
import Image from 'next/image'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import { rem } from 'src/common/utils/css'
import theme, { COLOR } from 'src/styles/theme'
import Breadcrumbs from 'src/components/breadcrumbs/Breadcrumbs'

const useStyles = makeStyles()((defaultTheme, props) => ({
  wrapper: {
    background: COLOR.LIGHT_BACKGROUND,
    padding: `${rem(40)} ${rem(0)}`,
    [theme.breakpoints.up('md')]: {
      padding: `${rem(28)} ${rem(0)} ${rem(64)}`,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${rem(0)} ${rem(23)}`,
    gap: rem(24),
    [theme.breakpoints.up('md')]: {
      padding: `${rem(0)} ${rem(40)}`,
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${rem(0)} ${rem(156)}`,
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: rem(12),
    [theme.breakpoints.up('md')]: {
      minWidth: rem(647),
    },
  },
  imageBox: {
    width: '100%',
    aspectRatio: '329/170',
    position: 'relative',
    background: theme.palette.primary.main,
    borderRadius: rem(4),
    [theme.breakpoints.up('md')]: {
      aspectRatio: '648/360',
    },
  },
  imageCaption: {
    ...theme.typography.smallBody.default,
    color: COLOR.DARK_GRAY,
    fontSize: rem(12),
    lineHeight: rem(14),
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    [theme.breakpoints.up('lg')]: {
      minWidth: rem(273),
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: rem(443),
    },
  },
  dateText: {
    ...theme.typography.body.bold,
    color: COLOR.MID_GRAY,
  },
  headingText: {
    ...theme.typography.h2,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: rem(48),
      fontWeight: 500,
      lineHeight: rem(44),
    },
  },
  descriptionText: {
    ...theme.typography.largeBody.default,
    color: COLOR.DARK_GRAY,
  },
  authorBoxContainer: {
    marginTop: rem(12),
  },
  authorBox: {
    borderLeft: `solid ${theme.palette.primary.dark} ${rem('2')}`,
    paddingLeft: rem('8'),
    color: theme.palette.primary.dark,
  },
  authorNameText: {
    ...theme.typography.h4,
    fontSize: rem(24),
    fontWeight: 400,
    lineHeight: rem(34.56),
  },
  authorTitleText: {
    ...theme.typography.body.default,
    display: 'block',
  },
  breadcrumbs: {
    width: '100%',
    padding: `${rem(0)} ${rem(23)} ${rem(26)} ${rem(23)}`,
    [theme.breakpoints.up('md')]: {
      padding: `${rem(0)} ${rem(40)} ${rem(52)} ${rem(40)}`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${rem(0)} ${rem(156)} ${rem(52)} ${rem(156)}`,
    },
  },
  matchImageBoxAlignment: {
    [theme.breakpoints.up('lg')]: {
      height: rem(26),
    },
  },
}))

function BlogPost(props) {
  const { data } = props
  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { blog = {}, enable_breadcrumb, breadcrumb: breadcrumbs = [] } = data

  // guards
  if (!blog || Object.keys(data).length === 0) {
    return null
  }

  const {
    hero_image: image,
    publish_date: date,
    title: heading,
    description,
    author,
    author_title_byline: authorTitle,
  } = blog || {}

  const { classes } = useStyles({})
  const backgroundType = 'light'
  const pattern = '/assets/patterns/manzanita/TransparentWhite_CropLeft.png'

  // Format the date as "MMM DD, YYYY"
  const formattedDate = new Date(date * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  return (
    <Box className={`${classes.wrapper} module`}>
      {enable_breadcrumb && breadcrumbs.length > 1 && (
        <Box className={classes.breadcrumbs}>
          <Breadcrumbs
            data={{
              breadcrumbs,
              backgroundType,
              customBreadCrumbs: data.customBreadCrumbs || {},
            }}
          />
        </Box>
      )}
      <Box className={`${classes.container}`}>
        <Box className={classes.imageContainer}>
          <Box className={classes.imageBox}>
            <Image
              style={{ borderRadius: rem(4) }}
              src={image?.url || pattern}
              alt={image?.alt || 'Blog post Image'}
              title={image?.title}
              layout="fill"
              objectFit="cover"
              priority
            />
          </Box>
          {image?.caption && (
            <Typography
              component="div"
              variant="smallBody"
              className={classes.imageCaption}
            >
              {image?.caption || ''}
            </Typography>
          )}
        </Box>
        <Box className={classes.contentContainer}>
          {formattedDate && formattedDate !== 'Invalid Date' && (
            <Typography className={classes.dateText}>
              {formattedDate}
            </Typography>
          )}
          <Typography component="h1" className={classes.headingText}>
            {heading}
          </Typography>
          <Typography variant="largeBody" className={classes.descriptionText}>
            {description}
          </Typography>
          <Box className={classes.authorBoxContainer}>
            <Box className={classes.authorBox}>
              <Typography className={classes.authorNameText}>
                {author}
              </Typography>
              <Typography
                variant="largeBody"
                className={classes.authorTitleText}
              >
                {authorTitle}
              </Typography>
            </Box>
            {image?.caption && (
              <div className={classes.matchImageBoxAlignment} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BlogPost

BlogPost.propTypes = {
  data: PropTypes.shape({
    blog: PropTypes.shape({
      hero_image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
        caption: PropTypes.string,
      }),
      publish_date: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      author: PropTypes.string,
      author_title_byline: PropTypes.string,
    }),
    breadcrumb: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
}
