import SmallLogoAnimated from 'src/components/icons/SmallLogoAnimated'

import { COLOR } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'

export default function Loader(props) {
  const {
    iconWidth,
    iconHeight,
    loadingComplete = false,
    minimumLoaderDuration = 1500,
    isType,
  } = props
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: !isType && COLOR.LIGHT_BACKGROUND,
        ...(isType && {
          position: 'absolute',
          top: rem(80),
          alignItems: 'start',
          zIndex: 1,
        }),
      }}
    >
      <SmallLogoAnimated
        width={iconWidth || 126}
        height={iconHeight || 168}
        loadingComplete={loadingComplete}
        minimumLoaderDuration={minimumLoaderDuration}
      />
    </div>
  )
}
