import React from 'react'

function UserInterestIcon(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46447 15.4645C5.40215 14.5268 6.67392 14 8 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H8C7.20435 16 6.44129 16.3161 5.87868 16.8787C5.31607 17.4413 5 18.2044 5 19V21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21V19C3 17.6739 3.52678 16.4021 4.46447 15.4645Z"
        fill={color || '#1F4D25'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4ZM7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7Z"
        fill={color || '#1F4D25'}
      />
      <path
        d="M17.5628 15.6865L17.7489 15.8708L17.9372 15.6865C18.1582 15.4688 18.4206 15.2962 18.7093 15.1784C18.9981 15.0606 19.3075 15 19.6201 15C19.9326 15 20.2421 15.0606 20.5308 15.1784C20.8196 15.2962 21.0819 15.4688 21.3029 15.6865C21.5239 15.9041 21.6992 16.1625 21.8188 16.4468C21.9384 16.7312 22 17.036 22 17.3437C22 17.6515 21.9384 17.9563 21.8188 18.2407C21.6992 18.525 21.5239 18.7834 21.3029 19.001L18.4507 21.8091C18.0614 22.1924 17.4366 22.1923 17.0474 21.8089L14.1971 19.001C13.7507 18.5615 13.5 17.9653 13.5 17.3437C13.5 16.7221 13.7507 16.126 14.1971 15.6865C14.6434 15.2469 15.2487 15 15.8799 15C16.5111 15 17.1165 15.2469 17.5628 15.6865Z"
        fill={color || '#1F4D25'}
      />
    </svg>
  )
}

export default UserInterestIcon
