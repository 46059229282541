import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'next/image'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import pattern from 'public/assets/patterns/land-sea/CrissyField_CropRight.png'
import { rem } from 'src/common/utils/css'
import AttractionBookOpen from 'src/components/icons/AttractionBookOpen'
import AttractionDining from 'src/components/icons/AttractionDining'
import AttractionMap from 'src/components/icons/AttractionMap'
import AttractionMartini from 'src/components/icons/AttractionMartini'
import AttractionStar from 'src/components/icons/AttractionStar'
import AttractionUserInterest from 'src/components/icons/AttractionUserInterest'
import theme from 'src/styles/theme'

const useStyles = makeStyles(theme)(() => ({
  wrapper: {
    position: 'relative',
    backgroundColor: theme.palette.presidio.color.BAKER_BEACH_WHITE,
    overflow: 'hidden',
  },
  barContent: {
    [theme.breakpoints.up('xs')]: {
      padding: `${rem(24)}`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${rem(24)} ${rem(40)}`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${rem(24)} ${rem(156)}`,
    },
  },
  backgroundImageLayer: {
    position: 'absolute',
    bottom: 0,
    top: '-40%',
    right: 0,
    height: '250%',
    width: '12%',
  },
  headingAndDescription: {
    [theme.breakpoints.up('xl')]: {
      minWidth: rem(168),
      width: rem(168),
    },
  },
  headingBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: rem(8),
    '& svg': {
      [theme.breakpoints.up('xs')]: {
        height: rem(18),
      },
      [theme.breakpoints.up('sm')]: {
        height: rem(18),
      },
      [theme.breakpoints.up('md')]: {
        height: rem(22),
      },
    },
  },
  heading: {
    color: theme.palette.primary.dark,
    paddingTop: rem(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: rem(18),
      paddingTop: rem(4),
    },
  },
  barDescription: {
    ...theme.typography.body.default,
    color: theme.palette.presidio.color.DARK_GRAY,
    fontSize: rem(16),
    paddingTop: rem(8),
  },
  iconBox: {
    flexShrink: 0,
    height: rem(20),
    width: rem(20),
    [theme.breakpoints.up('lg')]: {
      height: rem(24),
      width: rem(24),
    },
  },
}))

export default function AttractionMetricsBar(props) {
  const { data } = props
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { attraction_metrics_bar, section_id } = data
  if (!attraction_metrics_bar) return null

  const {
    region,
    amenities,
    dining_features,
    dining_style,
    place_type,
    use_type,
    interest,
  } = attraction_metrics_bar

  const { classes } = useStyles()

  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))

  const itemCount = React.useMemo(() => {
    let count = 0

    if (region) count++
    if (amenities) count++
    if (dining_features) count++
    if (dining_style) count++
    if (place_type) count++
    if (use_type) count++
    if (interest) count++

    return count
  }, [])

  const lgColumn = React.useMemo(() => {
    if (itemCount === 4 || itemCount === 7) return 3
    return 4
  }, [itemCount])

  const lgAmenities = React.useMemo(() => {
    if (itemCount === 5 || itemCount === 7) return 8
    return lgColumn
  }, [lgColumn, itemCount])

  const mdColumn = React.useMemo(() => {
    if (itemCount % 2 === 0) return 6
    return 4
  }, [itemCount])

  const mdAmenities = React.useMemo(() => {
    if (itemCount % 2 === 0) return 6
    if (itemCount === 5) return 8
    if (itemCount === 7) return 12
    return 4
  }, [itemCount])

  const layoutRegion = React.useMemo(() => {
    if (!region || !Array.isArray(region) || region.length === 0) {
      return null
    }

    return (
      <div className={classes.headingAndDescription}>
        <div className={classes.headingBlock}>
          <Box className={classes.iconBox}>
            <AttractionMap />
          </Box>
          <Typography variant="h4" className={classes.heading}>
            Region
          </Typography>
        </div>
        <Typography className={classes.barDescription}>
          {region.map((item, i) => (
            <span key={i}>{(i ? ', ' : '') + item.name}</span>
          ))}
        </Typography>
      </div>
    )
  }, [])

  const layoutPlaceType = React.useMemo(() => {
    if (!place_type || !Array.isArray(place_type) || place_type.length === 0) {
      return null
    }

    return (
      <div className={classes.headingAndDescription}>
        <div className={classes.headingBlock}>
          <Box className={classes.iconBox}>
            <AttractionBookOpen />
          </Box>
          <Typography variant="h4" className={classes.heading}>
            Type
          </Typography>
        </div>
        <Typography className={classes.barDescription}>
          {place_type.map((item, i) => (
            <span key={i}>{(i ? ', ' : '') + item.name}</span>
          ))}
        </Typography>
      </div>
    )
  }, [])

  const layoutDiningStyle = React.useMemo(() => {
    if (
      !dining_style ||
      !Array.isArray(dining_style) ||
      dining_style.length === 0
    )
      return null

    return (
      <div className={classes.headingAndDescription}>
        <div className={classes.headingBlock}>
          <Box className={classes.iconBox}>
            <AttractionDining />
          </Box>
          <Typography variant="h4" className={classes.heading}>
            Dining Style
          </Typography>
        </div>
        <Typography className={classes.barDescription}>
          {dining_style.map((item, i) => (
            <span key={i}>{(i ? ', ' : '') + item.name}</span>
          ))}
        </Typography>
      </div>
    )
  }, [])

  const layoutInterest = React.useMemo(() => {
    if (!interest || !Array.isArray(interest) || interest.length === 0) {
      return null
    }

    return (
      <div className={classes.headingAndDescription}>
        <div className={classes.headingBlock}>
          <Box className={classes.iconBox}>
            <AttractionUserInterest />
          </Box>
          <Typography variant="h4" className={classes.heading}>
            Interests
          </Typography>
        </div>
        <Typography className={classes.barDescription}>
          {interest.map((item, i) => (
            <span key={i}>{(i ? ', ' : '') + item.name}</span>
          ))}
        </Typography>
      </div>
    )
  }, [])

  const layoutDiningFeatures = React.useMemo(() => {
    if (
      !dining_features ||
      !Array.isArray(dining_features) ||
      dining_features.length === 0
    ) {
      return null
    }

    return (
      <div className={classes.headingAndDescription}>
        <div className={classes.headingBlock}>
          <Box className={classes.iconBox}>
            <AttractionMartini />
          </Box>
          <Typography
            variant="h4"
            className={classes.heading}
            style={{ minWidth: 'fit-content' }}
          >
            Dining Features
          </Typography>
        </div>
        <Typography className={classes.barDescription}>
          {dining_features.map((item, i) => (
            <span key={i}>{(i ? ', ' : '') + item.name}</span>
          ))}
        </Typography>
      </div>
    )
  }, [])

  const layoutUseType = React.useMemo(() => {
    if (!use_type || !Array.isArray(use_type) || use_type.length === 0) {
      return null
    }

    return (
      <div className={classes.headingAndDescription}>
        <div className={classes.headingBlock}>
          <Box className={classes.iconBox}>
            <AttractionBookOpen />
          </Box>
          <Typography variant="h4" className={classes.heading}>
            Use Type
          </Typography>
        </div>
        <Typography className={classes.barDescription}>
          {use_type.map((item, i) => (
            <span key={i}>{(i ? ', ' : '') + item.name}</span>
          ))}
        </Typography>
      </div>
    )
  }, [])

  const layoutAmenities = React.useMemo(() => {
    if (!amenities || !Array.isArray(amenities) || amenities.length === 0) {
      return null
    }

    return (
      <div>
        <div className={classes.headingBlock}>
          <Box className={classes.iconBox}>
            <AttractionStar />
          </Box>
          <Typography variant="h4" className={classes.heading}>
            Top Amenities
          </Typography>
        </div>
        <Typography className={classes.barDescription}>
          {amenities.map((item, i) => (
            <span key={i}>{(i ? ', ' : '') + item.name}</span>
          ))}
        </Typography>
      </div>
    )
  }, [])

  const backgroundImage = React.useMemo(() => {
    if (!xlUp) return null
    return (
      <div className={classes.backgroundImageLayer}>
        <Image
          src={pattern}
          alt="Land and sea background image"
          title="Land and sea background image"
          layout="fill"
        />
      </div>
    )
  }, [xlUp])

  const layoutContent = React.useMemo(() => {
    if (xlUp) {
      return (
        <Stack direction="row" spacing={3}>
          {layoutRegion}
          {layoutPlaceType}
          {layoutDiningStyle}
          {layoutInterest}
          {layoutDiningFeatures}
          {layoutUseType}
          {layoutAmenities}
        </Stack>
      )
    }

    return (
      <Grid container spacing={mdUp ? 3 : 2}>
        {region && Array.isArray(region) && region.length !== 0 && (
          <Grid item xs={6} sm={6} md={mdColumn} lg={lgColumn}>
            {layoutRegion}
          </Grid>
        )}
        {place_type && Array.isArray(place_type) && place_type.length !== 0 && (
          <Grid item xs={6} sm={6} md={mdColumn} lg={lgColumn}>
            {layoutPlaceType}
          </Grid>
        )}
        {dining_style &&
          Array.isArray(dining_style) &&
          dining_style.length !== 0 && (
            <Grid item xs={6} sm={6} md={mdColumn} lg={lgColumn}>
              {layoutDiningStyle}
            </Grid>
          )}
        {interest && Array.isArray(interest) && interest.length !== 0 && (
          <Grid item xs={6} sm={6} md={mdColumn} lg={lgColumn}>
            {layoutInterest}
          </Grid>
        )}
        {dining_features &&
          Array.isArray(dining_features) &&
          dining_features.length !== 0 && (
            <Grid item xs={6} sm={6} md={mdColumn} lg={lgColumn}>
              {layoutDiningFeatures}
            </Grid>
          )}
        {use_type && Array.isArray(use_type) && use_type.length !== 0 && (
          <Grid item xs={6} sm={6} md={mdColumn} lg={lgColumn}>
            {layoutUseType}
          </Grid>
        )}
        {amenities && Array.isArray(amenities) && amenities.length !== 0 && (
          <Grid item xs={12} sm={12} md={mdAmenities} lg={lgAmenities}>
            {layoutAmenities}
          </Grid>
        )}
      </Grid>
    )
  }, [xlUp, mdUp])

  return (
    <div
      className={`${classes.wrapper} module`}
      id={section_id}
      data-id="section"
    >
      <div className={classes.barContent}>
        {backgroundImage}
        {layoutContent}
      </div>
    </div>
  )
}

AttractionMetricsBar.propTypes = {
  data: PropTypes.shape({
    section_id: PropTypes.string,
    attraction_metrics_bar: PropTypes.shape({
      region: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      amenities: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      dining_features: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      dining_style: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      place_type: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      use_type: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      interest: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
  }),
}
