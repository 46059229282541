import { Box, Typography, Link as MuiLink } from '@mui/material'
import Image from 'next/image'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import { rem } from 'src/common/utils/css'
import theme, { COLOR } from 'src/styles/theme'
import Breadcrumbs from 'src/components/breadcrumbs/Breadcrumbs'
import CalenderEvent from 'src/components/icons/CalenderEvent'
import Calender from 'src/components/icons/Calendar'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'
import User from 'src/components/icons/User'
import DollarSign from 'src/components/icons/DollarSign'
import MapPin from 'src/components/icons/MapPin'
import Button from 'src/common/components/button/Button'
import sanitize from 'src/common/utils/js/sanitize'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import ArrowRight from 'src/components/icons/ArrowRight'

const useStyles = makeStyles()((defaultTheme, props) => ({
  wrapper: {
    background: COLOR.NEAR_WHITE,
    padding: `${rem(40)} ${rem(0)}`,
    [theme.breakpoints.up('md')]: {
      padding: `${rem(28)} ${rem(0)} ${rem(64)}`,
    },
  },

  breadcrumbs: {
    width: '100%',
    padding: `${rem(0)} ${rem(24)} ${rem(24)} ${rem(24)}`,
    [theme.breakpoints.up('md')]: {
      padding: `${rem(0)} ${rem(40)} ${rem(24)} ${rem(40)}`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${rem(0)} ${rem(156)} ${rem(26)} ${rem(156)}`,
    },
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(32),
    padding: `${rem(0)} ${rem(24)}`,
    [theme.breakpoints.up('md')]: {
      padding: `${rem(0)} ${rem(40)}`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${rem(0)} ${rem(156)}`,
    },
  },

  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    paddingTop: rem(40),
    [theme.breakpoints.up('lg')]: {
      paddingTop: rem(64),
    },
  },
  seriesIndicator: {
    display: 'flex',
    flexDirection: 'row',
    gap: rem(4),
    alignItems: 'center',
  },
  headingText: {
    ...theme.typography.h2,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: rem(48),
      fontWeight: 500,
      lineHeight: rem(44),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60%',
    },
  },
  descriptionText: {
    ...theme.typography.largeBody.default,
    color: COLOR.DARK_GRAY,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60%',
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: rem(24),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: rem(58),
    },
  },

  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: rem(12),
  },
  imageBox: {
    width: '100%',
    aspectRatio: '327/300',
    position: 'relative',
    background: theme.palette.primary.main,
    borderRadius: rem(4),
    [theme.breakpoints.up('md')]: {
      aspectRatio: '640/462',
    },
    [theme.breakpoints.up('lg')]: {
      aspectRatio: '560/462',
    },
    [theme.breakpoints.up('xl')]: {
      aspectRatio: '742/462',
    },
  },
  imageCaption: {
    ...theme.typography.smallBody.default,
    color: COLOR.DARK_GRAY,
    fontSize: rem(12),
    lineHeight: rem(14),
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    [theme.breakpoints.up('lg')]: {
      width: rem(328),
    },
    [theme.breakpoints.up('xl')]: {
      width: rem(328),
    },
  },
  dateText: {
    ...theme.typography.body.bold,
    color: COLOR.MID_GRAY,
  },

  infoBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: rem(16),
  },
  iconBox: {
    flexShrink: 0,
    height: rem(24),
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(5),
  },
  infoHeaderText: {
    ...theme.typography.h5,
    fontSize: rem(18),
    fontWeight: 400,
    color: COLOR.MID_GRAY,
  },
  infoDayText: {
    ...theme.typography.body.default,
    color: COLOR.BLACK,
  },
  animatedUnderline: {
    ...theme.typography.body.inlineLink,
  },

  matchImageBoxAlignment: {
    [theme.breakpoints.up('lg')]: {
      height: rem(26),
    },
  },
  ctaWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: rem(16),
    [theme.breakpoints.up('lg')]: {
      marginTop: rem(32),
    },
    [theme.breakpoints.up('lg')]: {
      width: rem(328),
    },
  },
  cta: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: rem(296),
    },
  },
  wysiwyg: {
    'p, ul, ol': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    'ul, ol': {
      paddingInlineStart: rem(20),
    },
    a: {
      ...theme.typography.body.inlineLink,
      color: theme.palette.primary.dark,
    },
  },
}))

function Event(props) {
  const { data } = props
  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const {
    tribe_events = {},
    enable_breadcrumb,
    breadcrumb: breadcrumbs = [],
  } = data

  // guards
  if (!tribe_events || Object.keys(data).length === 0) {
    return null
  }

  const {
    hero_image: image,
    heading,
    subheading,
    recurring_event,
    calender_download_link,
    add_to_calendar_link_title,
    frequency,
    event_start_date: date,
    event_start_time,
    event_end_time,
    hours,
    event_location,
    event_age_guidance,
    event_price,
    register_cta_title,
    register_url,
  } = tribe_events || {}

  const { classes } = useStyles({})
  const backgroundType = 'light'
  const pattern = '/assets/patterns/manzanita/TransparentWhite_CropLeft.png'

  // Format the date as "weekday, MMM DD, YYYY"
  const formattedDate = new Date(date * 1000).toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  let timeString = ''
  if (event_start_time) {
    timeString = event_start_time.toUpperCase()
    if (event_end_time) {
      timeString += ` - ${event_end_time.toUpperCase()}`
    }
  }
  if (hours) {
    timeString += ` (${hours} hours)`
  }

  const linkClickHandler = useLinkClickHandler()

  const registerUrl = { url: register_url, target: '_blank' }

  return (
    <Box className={`${classes.wrapper} module`}>
      {enable_breadcrumb && breadcrumbs.length > 1 && (
        <Box className={classes.breadcrumbs}>
          <Breadcrumbs
            data={{
              breadcrumbs,
              backgroundType,
              customBreadCrumbs: data.customBreadCrumbs || {},
            }}
          />
        </Box>
      )}
      <Box className={classes.contentWrapper}>
        <Box className={classes.heading}>
          {recurring_event && (
            <Box className={classes.seriesIndicator}>
              <CalenderEvent color={COLOR.MID_GRAY} />
              <Typography
                style={{ ...theme.typography.body.bold, color: COLOR.MID_GRAY }}
              >
                Event Series
              </Typography>
            </Box>
          )}
          <Typography component="h1" className={classes.headingText}>
            {heading}
          </Typography>
          <Typography variant="largeBody" className={classes.descriptionText}>
            {subheading}
          </Typography>
        </Box>
        <Box className={`${classes.container}`}>
          <Box className={classes.imageContainer}>
            <Box className={classes.imageBox}>
              <Image
                style={{ borderRadius: rem(4) }}
                src={image?.url || pattern}
                alt={image?.alt || 'Event hero Image'}
                title={image?.title}
                layout="fill"
                objectFit="cover"
                priority
              />
            </Box>
            {image?.caption && (
              <Typography
                component="div"
                variant="smallBody"
                className={classes.imageCaption}
              >
                {image?.caption || ''}
              </Typography>
            )}
          </Box>
          <Box className={classes.contentContainer}>
            {/* Date & Time */}
            <Box className={classes.infoBoxContainer}>
              <Box className={classes.iconBox}>
                <Calender color={COLOR.MID_GRAY} />
              </Box>
              <Box className={classes.infoBox}>
                <Typography component="span" className={classes.infoHeaderText}>
                  Date & Time
                </Typography>
                {formattedDate && formattedDate !== 'Invalid Date' && (
                  <Typography className={classes.infoDayText}>
                    {formattedDate}
                  </Typography>
                )}
                <Typography className={classes.infoDayText}>
                  {timeString}
                </Typography>
                {calender_download_link && calender_download_link !== '' && (
                  <MuiLink
                    className={`${classes.infoDayText} ${classes.animatedUnderline}`}
                    style={{
                      color: theme.palette.primary.dark,
                      width: 'fit-content',
                    }}
                    href={calender_download_link}
                    target="_blank"
                    rel="noopener"
                    underline="none"
                  >
                    {add_to_calendar_link_title || 'Add to calendar'}
                  </MuiLink>
                )}
              </Box>
            </Box>
            {/* Frequency */}
            {frequency && (
              <Box className={classes.infoBoxContainer}>
                <Box className={classes.iconBox}>
                  <CalenderEvent color={COLOR.MID_GRAY} />
                </Box>
                <Box className={classes.infoBox}>
                  <Typography
                    component="span"
                    className={classes.infoHeaderText}
                  >
                    Frequency
                  </Typography>
                  <Typography className={classes.infoDayText}>
                    {frequency}
                  </Typography>
                </Box>
              </Box>
            )}
            {/* Location */}
            {event_location && (
              <Box className={classes.infoBoxContainer}>
                <Box className={classes.iconBox}>
                  <MapPin color={COLOR.MID_GRAY} />
                </Box>
                <Box className={classes.infoBox}>
                  <Typography
                    component="span"
                    className={classes.infoHeaderText}
                  >
                    Location
                  </Typography>
                  <Box className={`${classes.wysiwyg} ${classes.infoDayText}`}>
                    {sanitize(event_location)}
                  </Box>
                </Box>
              </Box>
            )}
            {/* Age Guidance */}
            {event_age_guidance && (
              <Box className={classes.infoBoxContainer}>
                <Box className={classes.iconBox}>
                  <User color={COLOR.MID_GRAY} />
                </Box>
                <Box className={classes.infoBox}>
                  <Typography
                    component="span"
                    className={classes.infoHeaderText}
                  >
                    Age Guidance
                  </Typography>
                  <Box className={`${classes.wysiwyg} ${classes.infoDayText}`}>
                    {sanitize(event_age_guidance)}
                  </Box>
                </Box>
              </Box>
            )}
            {/* Price */}
            {event_price && (
              <Box className={classes.infoBoxContainer}>
                <Box className={classes.iconBox}>
                  <DollarSign color={COLOR.MID_GRAY} />
                </Box>
                <Box className={classes.infoBox}>
                  <Typography
                    component="span"
                    className={classes.infoHeaderText}
                  >
                    Price
                  </Typography>
                  <Box className={`${classes.wysiwyg} ${classes.infoDayText}`}>
                    {sanitize(event_price)}
                  </Box>
                </Box>
              </Box>
            )}
            <Box className={classes.ctaWrapper}>
              <Button
                component="a"
                href={registerUrl?.url}
                endIcon={
                  isUrlExternal(registerUrl?.url) ? (
                    <ButtonExternalLink />
                  ) : (
                    <ArrowRight />
                  )
                }
                className={classes.cta}
                onClick={(e) => {
                  e.preventDefault()
                  linkClickHandler(registerUrl)
                }}
              >
                {register_cta_title || 'Register'}
              </Button>
            </Box>
            {image?.caption && (
              <div className={classes.matchImageBoxAlignment} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Event

Event.propTypes = {
  data: PropTypes.shape({
    tribe_events: PropTypes.shape({
      hero_image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
        caption: PropTypes.string,
      }),
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string,
      recurring_event: PropTypes.bool.isRequired,
      add_to_calendar_link_title: PropTypes.string,
      frequency: PropTypes.string,
      event_start_date: PropTypes.number,
      event_start_time: PropTypes.string.isRequired,
      event_end_time: PropTypes.string,
      hours: PropTypes.number,
      event_location: PropTypes.string,
      event_age_guidance: PropTypes.string,
      event_price: PropTypes.string,
      register_cta_title: PropTypes.string,
      register_url: PropTypes.string,
    }),
    breadcrumb: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
}
