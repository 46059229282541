import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import Image from 'next/image'
import { Box, Typography, useMediaQuery } from '@mui/material'
import theme, { COLOR, SECONDARY_COLOR } from 'src/styles/theme'
import Button from 'src/common/components/button/Button'
import Video from 'src/common/components/video/Video'
import { COLOR_THEME, PHOTO_FRAME_BASE64_STRING } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import Loader from 'src/common/components/loader/Loader'
import PauseCircle from 'src/components/icons/PauseCircle'
import PlayCircle from 'src/components/icons/PlayCircle'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()((_theme, { background, aspect, isVideo }) => {
  let backgroundColor
  let textColor
  switch (background) {
    case 'crissy_field':
      /* eslint-disable prefer-destructuring */
      backgroundColor = SECONDARY_COLOR.LIGHT[40]
      textColor = theme.palette.primary.dark
      break
    case 'baker_beach':
      backgroundColor = COLOR.BAKER_BEACH_WHITE
      textColor = theme.palette.primary.dark
      break
    default:
      backgroundColor = theme.palette.primary.dark
      textColor = COLOR.NEAR_WHITE
  }
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: backgroundColor,
      paddingTop: `${rem(40)}`,
      [theme.breakpoints.up('md')]: {
        paddingTop: `${rem(48)}`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0 !important',
        flexDirection: 'row',
      },
    },
    bannerTextBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(24),
      padding: `${rem(40)} ${rem(24)}`,
      paddingTop: '0',
      [theme.breakpoints.up('lg')]: {
        padding: `${rem(89)} ${rem(16)} ${rem(89)} ${rem(40)}`,
        justifyContent: 'center',
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(146)} ${rem(24)} ${rem(146)} ${rem(156)}`,
        width: rem(516),
      },
    },
    bannerTextPrimary: {
      color: textColor,
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        width: rem(347),
      },
      [theme.breakpoints.up('xl')]: {
        width: rem(336),
      },
    },
    bannerTextSecondary: {
      ...theme.typography.largeBody.default,
      color: textColor,
    },
    ctaButton: {
      width: 'fit-content',
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    bannerVideoBox: {
      // eslint-disable-next-line no-nested-ternary
      height: isVideo ? 'auto' : aspect === 'landscape' ? rem(280) : rem(440),
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        height: isVideo ? 'auto' : rem(446),
      },
      [theme.breakpoints.up('lg')]: {
        height: 'auto',
      },
    },
    playButton: {
      position: 'absolute',
      bottom: rem(24),
      right: rem(24),
      width: rem(128),
      [theme.breakpoints.up('md')]: {
        bottom: rem(40),
        right: rem(40),
      },
    },
    imageMask: {
      WebkitMaskImage: `url(data:image/svg+xml;base64,${PHOTO_FRAME_BASE64_STRING.SEMI_CIRCLE_IMAGE_RIGHT})`,
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskSize: 'cover',
      WebkitMaskPosition: 'left',
      objectFit: 'cover',
    },
    videoBox: {
      height: isVideo ? 'auto' : '100%',
      aspectRatio: isVideo ? '16/9' : '',
      [theme.breakpoints.up('lg')]: {
        height: '100%',
      },
    },
    posterImage: {
      position: 'absolute',
      // opacity: 1,
      // transition: 'opacity 500ms ease-in-out',
    },
    video: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0,
      transition: 'opacity 500ms ease-in-out',
    },
  }
})

export default function HeroHome(props) {
  const { data } = props
  if (!data) return null

  const { hero, section_id } = data
  if (!hero) return null

  const {
    page_level: pageLevel,
    media,
    desktop_image: desktopImage,
    mobile_image: mobileImage,
    video_source: videoSource,
    youtube_url: youtubeUrl = '',
    wordpress_video: wordpressVideo,
    shape_frame: shapeFrame,
    media_aspect_ration: aspect,
    background_color: background,
    heading,
    sub_heading: subHeading,
    cta,
    select_viewports: ctaViewPort,
  } = hero

  if (pageLevel !== 'homepage') return null

  const isVideo = media === 'media_video'

  const { classes } = useStyles({
    background,
    aspect,
    isVideo,
  })

  const autoPlay = wordpressVideo?.autoplay

  const [player, setPlayer] = useState(null)
  const [playing, setPlaying] = useState(autoPlay)
  const [isBuffering, setIsBuffering] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [loadVideo, setLoadVideo] = useState(false)

  const linkClickHandler = useLinkClickHandler()

  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))

  const isYoutubeVideo = videoSource === 'youtube'
  const isWordpressVideo = videoSource === 'wordpress'

  let mp4Video
  let webmVideo
  let ogvVideo
  let posterImage

  if (isWordpressVideo) {
    mp4Video = wordpressVideo?.mp4_video?.url
    webmVideo = wordpressVideo?.webm_video?.url
    ogvVideo = wordpressVideo?.ogv_video?.url
    posterImage = wordpressVideo?.poster_image
  }

  // For Youtube Video
  const onReady = (event) => {
    if (isYoutubeVideo) {
      const minimumLoaderDuration = 1500
      const startTime = Date.now()

      setPlayer(event.target)
      event.target.playVideo()

      // Calculate the remaining time to wait before hiding the loader
      const remainingTime = minimumLoaderDuration - (Date.now() - startTime)

      // Hide the loader after the remaining time has passed
      setTimeout(
        () => {
          setIsLoading(false)
        },
        remainingTime > 0 ? remainingTime : 0
      )
    }
  }

  // For Custom Video
  useEffect(() => {
    if (isYoutubeVideo) return null

    const minimumLoaderDuration = 3000
    const posterImageTimer = setTimeout(() => {
      // const poster = document.getElementById('poster-image')
      // if (poster) {
      //   poster.style.opacity = 0
      // }

      setIsLoading(false)
    }, minimumLoaderDuration)

    const loadVideoTimer = setTimeout(() => {
      setLoadVideo(true)
    }, 2600)

    return () => {
      clearTimeout(posterImageTimer)
      clearTimeout(loadVideoTimer)
    }
  }, [])

  const handlePlayPause = () => {
    if (isYoutubeVideo) {
      if (playing) {
        player.pauseVideo()
        setPlaying(false)
      } else {
        player.playVideo()
        setPlaying(true)
      }
    } else {
      const customPlayer = document.getElementById('hero-video')

      if (playing) {
        customPlayer.pause()
        setPlaying(false)
      } else {
        customPlayer.play()
        setPlaying(true)
      }
    }
  }

  const handleBuffering = () => {
    setIsBuffering(true)
  }

  const handleBufferEnd = () => {
    setIsBuffering(false)
  }

  // Conditionally display CTA
  let displayCTA
  switch (ctaViewPort) {
    case 'mobile':
      displayCTA = !mdUp
      break
    case 'desktop':
      displayCTA = mdUp
      break
    default:
      displayCTA = true
  }

  // Conditionally select image
  let finalImage = desktopImage
  if (!mdUp) {
    if (mobileImage?.url) {
      finalImage = mobileImage
    }
  }

  return (
    <Box
      className={`${classes.wrapper} module`}
      id={section_id}
      data-id="section"
    >
      <Box className={classes.bannerTextBox}>
        <Typography variant="h1" className={classes.bannerTextPrimary}>
          {heading}
        </Typography>
        <Typography className={classes.bannerTextSecondary}>
          {subHeading}
        </Typography>
        {displayCTA && cta && cta.url && cta.title && (
          <a
            href={cta.url}
            style={{ width: 'fit-content' }}
            onClick={(e) => {
              e.preventDefault()
              linkClickHandler(cta)
            }}
            data-ga-location="hero"
          >
            <Button
              className={classes.ctaButton}
              variant={background !== 'cypress_green' ? 'primary' : 'secondary'}
              disableRipple
              tabIndex={-1}
              endIcon={isUrlExternal(cta?.url) ? <ButtonExternalLink /> : null}
            >
              {cta.title}
            </Button>
          </a>
        )}
      </Box>

      <Box className={classes.bannerVideoBox}>
        <Box
          className={`${shapeFrame && lgUp ? classes.imageMask : ''} ${
            classes.videoBox
          }`}
        >
          {isYoutubeVideo && isLoading && isVideo && (
            <Loader
              iconWidth={68}
              iconHeight={83}
              loadingComplete={!isLoading}
            />
          )}
          {isWordpressVideo && isVideo && posterImage && (
            <Image
              id="poster-image"
              src={posterImage.url}
              alt={posterImage.alt || 'Poster'}
              title={posterImage.title}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              priority
              className={classes.posterImage}
            />
          )}
          {/* Youtube Video */}
          {isYoutubeVideo && isVideo && (
            <div style={{ display: isLoading ? 'none' : 'block' }}>
              <Video
                variant="hero"
                shouldLazyLoad={false}
                video={{
                  videoId: youtubeUrl?.split('=')[1],
                  controls: false,
                  autoplay: autoPlay,
                  loop: true,
                  allowFullScreen: false,
                  muteByDefault: true,
                  onReady,
                }}
              />
              <Button
                className={classes.playButton}
                disableRipple
                variant="secondary"
                onClick={handlePlayPause}
                startIcon={playing ? <PauseCircle /> : <PlayCircle />}
                data-testid="yt-button"
                data-weglot="translate"
                aria-label={playing ? 'Pause' : 'Play'}
                style={{ display: `${isLoading ? 'none' : 'flex'}` }}
              >
                <span className="zh-keepWord">
                  {playing ? 'Pause' : 'Play'}
                </span>
              </Button>
            </div>
          )}
          {/* Custom Video */}
          {isWordpressVideo && isVideo && loadVideo && (
            <div
              style={{
                position: 'absolute',
                visibility: isLoading ? 'hidden' : 'visible',
                height: '100%',
                opacity: isLoading ? 0 : 1,
              }}
              className={classes.video}
            >
              {isBuffering && <div className="loading-spinner" />}
              <video
                id="hero-video"
                style={{ height: '100%' }}
                autoPlay={autoPlay}
                loop
                muted
                playsInline
                preload="none"
                onWaiting={handleBuffering}
                onPlaying={handleBufferEnd}
              >
                <source src={mp4Video} type="video/mp4" />
                <source src={webmVideo} type="video/webm" />
                <source src={ogvVideo} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
              <Button
                className={classes.playButton}
                disableRipple
                variant="secondary"
                onClick={handlePlayPause}
                startIcon={playing ? <PauseCircle /> : <PlayCircle />}
                data-testid="yt-button"
                data-weglot="translate"
                aria-label={playing ? 'Pause' : 'Play'}
                style={{ display: 'flex' }}
              >
                <span className="zh-keepWord">
                  {playing ? 'Pause' : 'Play'}
                </span>
              </Button>
            </div>
          )}
          {!isVideo && (
            <Image
              objectFit="cover"
              layout="fill"
              src={finalImage?.url}
              alt={finalImage?.alt || 'Hero image'}
              title={finalImage?.title}
              priority
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

HeroHome.propTypes = {
  data: PropTypes.shape({
    hero: PropTypes.shape({
      page_level: PropTypes.oneOf(['homepage']),
      heading: PropTypes.string,
      sub_heading: PropTypes.string,
      media: PropTypes.oneOf(['media_image', 'media_video']),
      desktop_image: PropTypes.oneOf([
        false,
        PropTypes.shape({
          url: PropTypes.string,
        }),
      ]),
      mobile_image: PropTypes.oneOf([
        false,
        PropTypes.shape({
          url: PropTypes.string,
        }),
      ]),
      shape_frame: PropTypes.bool,
      media_aspect_ration: PropTypes.oneOf(['portrait', 'landscape']),
      background_color: PropTypes.oneOf([
        COLOR_THEME.CRISSY_FIELD,
        COLOR_THEME.CYPRESS_GREEN,
        COLOR_THEME.THE_BAKER_BEACH,
      ]),
      cta: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
      select_viewports: PropTypes.oneOf(['mobile', 'desktop', 'both']),
      isYoutubeVideo: PropTypes.bool,
    }),
  }),
}
