import React from 'react'

function MartiniIcon(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 21C5 20.4477 5.44772 20 6 20H18C18.5523 20 19 20.4477 19 21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21Z"
        fill={color || '#1F4D25'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H7C6.44772 8 6 7.55228 6 7Z"
        fill={color || '#1F4D25'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C12.5523 12 13 12.4477 13 13V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V13C11 12.4477 11.4477 12 12 12Z"
        fill={color || '#1F4D25'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08652 2.59316C2.24718 2.23241 2.60511 2 3.00002 2H21C21.3949 2 21.7529 2.23241 21.9135 2.59316C22.0742 2.95391 22.0075 3.37543 21.7433 3.66896L12.7433 13.669C12.5537 13.8797 12.2835 14 12 14C11.7165 14 11.4464 13.8797 11.2567 13.669L2.25672 3.66896C1.99254 3.37543 1.92585 2.95391 2.08652 2.59316ZM5.24538 4L12 11.5052L18.7547 4H5.24538Z"
        fill={color || '#1F4D25'}
      />
    </svg>
  )
}

export default MartiniIcon
