import { COLOR_THEME } from 'src/common/constants'

export const getBackgroundType = (color) => {
  switch (color) {
    case COLOR_THEME.CYPRESS_GREEN:
    case COLOR_THEME.ROOFTOP_RED:
      return 'dark'
    default:
      return 'light'
  }
}
