import { Box, Typography, Link as MuiLink, useMediaQuery } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import theme, { SECONDARY_COLOR, COLOR } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'
import ChevronRight from 'src/components/icons/ChevronRight'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { background, textColor, hoverColor, pressedColor } = props

  return {
    container: {
      maxWidth: 'fit-content',
      padding: background === 'image' ? `${rem(5)} ${rem(8)}` : 'auto',
      backgroundColor:
        background === 'image' ? COLOR.LIGHT_BACKGROUND : 'transparent',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: rem(8),
    },
    breadBox: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      gap: rem(8),
    },
    linkText: {
      ...theme.typography.body.default,
      letterSpacing: 0,
      color: textColor,
      textDecorationColor: 'currentColor',
      marginTop: rem(-3.5),
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      '&:visited': {
        color: textColor,
      },
      '&:hover': {
        color: hoverColor,
      },
      '&:active': {
        color: pressedColor,
      },
    },
    noLinkText: {
      marginTop: rem(-2),
      color: textColor,
      '&:hover': {
        color: textColor,
      },
      '&:active': {
        color: textColor,
      },
      '&:visited': {
        color: textColor,
      },
    },
    animatedUnderline: {
      ...theme.typography.body.inlineLink,
      display: '-webkit-box',
      '&:after': {
        ...theme.typography.body.inlineLink['&:after'],
        bottom: rem(3.5),
      },
    },
  }
})

function Breadcrumbs(props) {
  const { data } = props

  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { customBreadCrumbs } = data

  const { breadcrumbs: links = [], backgroundType: background = '' } =
    data || {}

  let textColor = theme.palette.primary.dark
  let hoverColor = theme.palette.secondary.dark
  let pressedColor = SECONDARY_COLOR.DARK[60]
  const disabledColor = COLOR.MID_GRAY

  switch (background) {
    case 'dark':
      textColor = COLOR.LIGHT_BACKGROUND
      /* eslint-disable prefer-destructuring */
      hoverColor = SECONDARY_COLOR.LIGHT[60]
      pressedColor = theme.palette.secondary.light
      break
    case 'image':
      textColor = COLOR.DARK_GRAY
      break
    default:
      break
  }

  const { classes } = useStyles({
    background,
    textColor,
    hoverColor,
    pressedColor,
    disabledColor,
  })

  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))

  const router = useRouter()

  function linkClickHandler(action) {
    // Custom URL redirects to maintain page hierarchy
    function getFormattedUrl(url) {
      switch (url) {
        case '/venues':
          return '/plan-an-event'
        case '/explore/attractions':
          return '/explore/park-attractions'
        default:
          return url
      }
    }

    const formattedUrl = getFormattedUrl(action.url)

    if (action.target === '_blank') {
      window.open(formattedUrl, '_blank')
    } else {
      router.push(formattedUrl)
    }
  }

  return (
    <Box
      className={classes.container}
      aria-label="breadcrumb"
      role="navigation"
    >
      {links.length > 1 &&
        links.map((link, index) => (
          <Box key={link.name} className={classes.breadBox}>
            {index > 0 && (
              <Box sx={{ width: rem(18), height: rem(18) }}>
                <ChevronRight height={18} width={18} color={textColor} />
              </Box>
            )}
            {index === links.length - 1 ? (
              <Typography
                aria-current="page"
                className={`${classes.linkText} ${classes.noLinkText}`}
                tabIndex={-1}
                data-ga-location={`breadcrumb_${index}`}
              >
                {customBreadCrumbs[link.name] || link.name}
              </Typography>
            ) : (
              <MuiLink
                className={`${classes.linkText} ${
                  mdUp ? classes.animatedUnderline : ''
                }`}
                href={link?.url}
                underline={mdUp ? 'none' : 'always'}
                onClick={(e) => {
                  e.preventDefault()
                  linkClickHandler(link)
                }}
                data-ga-location={`breadcrumb_${index}`}
              >
                {customBreadCrumbs[link?.name] || link?.name}
              </MuiLink>
            )}
          </Box>
        ))}
    </Box>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  data: PropTypes.shape({
    backgroundType: PropTypes.oneOf(['light', 'dark', 'image']),
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
}
