import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import Image from 'next/image'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { useRouter } from 'next/router'
import pattern from 'public/assets/patterns/manzanita/Patterns_Manzanita_TransparentWhite_FullWidth 2.png'
import Button from 'src/common/components/button/Button'
import sanitize from 'src/common/utils/js/sanitize'
import { rem } from 'src/common/utils/css'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import { COLOR_THEME } from 'src/common/constants'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'

const BACKGROUND_COLORS = {
  crissy_field_green: SECONDARY_COLOR.LIGHT[60],
  rooftop_red: SECONDARY_COLOR.MAIN[80],
}

const useStyles = makeStyles()((defaultTheme, props) => ({
  container: {
    padding: `${rem(64)} ${rem(24)}`,
    [theme.breakpoints.up('md')]: {
      padding: `${rem(64)} ${rem(40)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${rem(64)} ${rem(140)}`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${rem(64)} ${rem(348)}`,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: BACKGROUND_COLORS[props.backgroundColor],
    position: 'relative',
    overflow: 'clip',
  },
  backgroundImageBox: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  backgroundImage: {
    objectFit: 'cover',
  },
  frame: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: rem(24),
    [theme.breakpoints.up('md')]: {
      padding: rem(40),
      alignItems: 'center',
    },
    gap: rem(24),
    backgroundColor: theme.palette.presidio.color.LIGHT_BACKGROUND,
    zIndex: 10,
  },
  imageBox: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  image: {
    objectFit: 'contain',
    objectPosition: 'center',
  },
  errorText: {
    ...theme.typography.h1,
    textAlign: 'center',
    color: theme.palette.primary.dark,
    fontSize: rem(64),
    [theme.breakpoints.up('md')]: {
      fontSize: rem(96),
    },
  },
  body: {
    ...theme.typography.largeBody.default,
    color: theme.palette.primary.dark,
    textAlign: 'center',
  },
  ctaButton: {
    width: '100%',
    fontWeight: 500,
    letterSpacing: 'normal',
    [theme.breakpoints.up('md')]: {
      width: 'fit-content',
    },
  },
}))

const MissionStatement = (props) => {
  const { data } = props
  if (!data || Object.keys(data).length === 0) return null

  const { section_id, mission_statement } = data
  const {
    background_color: backgroundColor,
    background_pattern: backgroundPattern,
    desktop_image: desktopImage,
    mobile_image: mobileImage,
    errorTitle = '',
    body,
    cta,
  } = mission_statement

  const hasCTA = !!cta?.title
  const hasDesktopImage = !!desktopImage?.url
  const hasMobileImage = !!mobileImage?.url
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))
  const { classes } = useStyles({ backgroundColor })

  const router = useRouter()

  function linkClickHandler(action) {
    if (action.target === '_blank') {
      window.open(action.url, '_blank')
    } else {
      router.push(action.url)
    }
  }

  return (
    <Box
      className={`module ${classes.container}`}
      id={section_id}
      data-id="section"
    >
      {mdUp && backgroundPattern && (
        <Box className={classes.backgroundImageBox}>
          <Image
            className={classes.backgroundImage}
            src={pattern}
            alt="Manzanita background image"
            title="Manzanita background image"
            layout="fill"
          />
        </Box>
      )}
      <Grid className={classes.frame}>
        {!errorTitle && mdUp && hasDesktopImage && (
          <Box
            className={classes.imageBox}
            style={{ height: mdUp ? rem(80) : rem(60) }}
          >
            <Image
              className={classes.image}
              src={desktopImage.url}
              alt={desktopImage.alt || 'Mission statement'}
              title={desktopImage?.title}
              layout="fill"
            />
          </Box>
        )}
        {!errorTitle && !mdUp && (hasDesktopImage || hasMobileImage) && (
          <Box
            className={classes.imageBox}
            style={{ height: mdUp ? rem(80) : rem(60) }}
          >
            <Image
              className={classes.image}
              src={hasMobileImage ? mobileImage.url : desktopImage.url}
              alt={
                (hasMobileImage ? mobileImage.alt : desktopImage.alt) ||
                'Mission statement image'
              }
              title={
                (hasMobileImage ? mobileImage?.title : desktopImage?.title) ||
                'Mission statement image'
              }
              layout="fill"
            />
          </Box>
        )}
        {errorTitle && (
          <Typography className={classes.errorText}>{errorTitle}</Typography>
        )}
        <div className={classes.body}>{sanitize(body)}</div>
        {hasCTA && (
          <a
            href={cta.url}
            onClick={(e) => {
              e.preventDefault()
              linkClickHandler(cta)
            }}
            data-ga-location={!errorTitle ? 'missionstatement_cta' : null}
          >
            <Button
              className={classes.ctaButton}
              variant="primary"
              tabIndex={-1}
              endIcon={isUrlExternal(cta.url) ? <ButtonExternalLink /> : null}
            >
              {cta.title}
            </Button>
          </a>
        )}
      </Grid>
    </Box>
  )
}

export default MissionStatement

export const PROPS_BACKGROUND_COLORS = [
  COLOR_THEME.ROOFTOP_RED,
  COLOR_THEME.CRISSY_FIELD_GREEN,
]

MissionStatement.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string.isRequired,
    section_id: PropTypes.string.isRequired,
    mission_statement: PropTypes.shape({
      desktop_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
        description: PropTypes.string,
        caption: PropTypes.string,
        name: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      mobile_image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
        description: PropTypes.string,
        caption: PropTypes.string,
        name: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      errorTitle: PropTypes.string,
      body: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.string,
      }),
      background_color: PropTypes.oneOf(PROPS_BACKGROUND_COLORS),
      background_pattern: PropTypes.bool,
    }),
  }),
}
