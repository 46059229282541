import React from 'react'

function DiningIcon(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3C5 2.44772 4.55228 2 4 2C3.44772 2 3 2.44772 3 3V9C3 11.419 4.71776 13.4367 7 13.9V22C7 22.5523 7.44772 23 8 23C8.55228 23 9 22.5523 9 22V13.9C11.2822 13.4367 13 11.419 13 9V3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V10H9V3C9 2.44772 8.55228 2 8 2C7.44772 2 7 2.44772 7 3V10H5V3Z"
        fill={color || '#1F4D25'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7C15 4.23858 17.2386 2 20 2C20.5523 2 21 2.44772 21 3V22C21 22.5523 20.5523 23 20 23C19.4477 23 19 22.5523 19 22V16H16C15.4477 16 15 15.5523 15 15V7ZM19 14V4.17071C17.8348 4.58254 17 5.69378 17 7V14H19Z"
        fill={color || '#1F4D25'}
      />
    </svg>
  )
}

export default DiningIcon
