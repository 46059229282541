/* eslint-disable no-prototype-builtins */
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import YouTube from 'react-youtube'
import PropTypes from 'prop-types'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

const useStyles = makeStyles()((props) => ({
  videoBox: {
    position: 'relative',
    height: '100%',
  },
  bannerVideoFrame: {
    aspectRatio: '16/9',
    width: '100%',
    marginBottom: '-9px',
  },
  pushBack: {
    pointerEvents: 'none',
  },
}))

const MyVideoComponent = (props) => {
  const { classes } = useStyles()

  const { variant, video } = props

  const isHero = variant === 'hero'

  const getValueOrDefault = (obj, key, defaultValue) =>
    obj.hasOwnProperty(key) ? obj[key] : defaultValue

  return (
    <YouTube
      className={`${classes.videoBox} ${isHero ? classes.pushBack : ''}`}
      iframeClassName={isHero ? classes.bannerVideoFrame : undefined}
      videoId={video.videoId}
      opts={{
        width: '100%',
        height: '100%',
        playerVars: {
          rel: 0,
          playlist: video.videoId,
          controls: getValueOrDefault(video, 'controls', true) ? 1 : 0,
          autoplay: getValueOrDefault(video, 'autoplay', false),
          loop: getValueOrDefault(video, 'loop', false),
          fs: getValueOrDefault(video, 'allowFullScreen', false),
          mute: getValueOrDefault(video, 'muteByDefault', true),
          modestbranding: true,
          origin: process.env.NEXT_PUBLIC_SERVER_URL,
        },
      }}
      onReady={video?.onReady}
      onStateChange={video?.onStateChange}
    />
  )
}

function Video(props) {
  const { shouldLazyLoad = true } = props

  if (!shouldLazyLoad) {
    return <MyVideoComponent {...props} />
  }

  return (
    <LazyLoadComponent>
      <MyVideoComponent {...props} />
    </LazyLoadComponent>
  )
}
export default Video

Video.propTypes = {
  variant: PropTypes.oneOf(['hero', 'normal']),
  video: PropTypes.shape({
    videoId: PropTypes.string.isRequired,
    autoPlay: PropTypes.bool,
    controls: PropTypes.bool,
    loop: PropTypes.bool,
    allowFullScreen: PropTypes.bool,
    muteByDefault: PropTypes.bool,
  }),
}
